import { createTranslationResource } from '@backstage/core-plugin-api/alpha';
import { scaffolderTranslationRef } from '@backstage/plugin-scaffolder/dist/alpha';
import { userSettingsTranslationRef } from '@backstage/plugin-user-settings/alpha';
import { toolboxTranslationRef } from '@drodil/backstage-plugin-toolbox';

export const userSettingsMessages = createTranslationResource({
  ref: userSettingsTranslationRef,
  translations: {
    es: () =>
      Promise.resolve({
        default: {
          'languageToggle.title': 'Idioma',
          'languageToggle.select': 'Idioma {{language}}',
          'languageToggle.description': 'Cambia el idioma',
          'themeToggle.title': 'Tema',
          'themeToggle.description': 'Cambia el tema',
          'themeToggle.select': 'Tema {{theme}}',
          'themeToggle.selectAuto': 'Auto',
          'themeToggle.names.auto': 'Auto',
          'themeToggle.names.dark': 'Oscuro',
          'themeToggle.names.light': 'Claro',
        },
      }),
  },
});

// export const toolboxMessages = createTranslationResource({
//     ref: toolboxTranslationRef,
//     translations: {
//       es: () =>
//         Promise.resolve({
//           default: {
//             'toolbox.title': 'Herramientas',
//             'toolbox.description': 'Herramientas de desarrollo',
//           },
//         }),
//     },
// });

// export const scaffolderMessages = createTranslationResource({
//     ref: scaffolderTranslationRef,
//     translations: {
//       es: () =>
//         Promise.resolve({
//           default: {
//             'scaffolder.title': 'Plantillas',
//             'scaffolder.description': 'Plantillas de desarrollo',
//           },
//     },
// });

