
export function splitIntervals(intervals: string) {
  const parts = intervals.split('/');
  const endDate = new Date(parts[2]);
  let startDate;

  switch (parts[1]) {
    case 'P7D':
      startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate() - 7,
      );
      break;
    case 'P30D':
      startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 1,
        endDate.getDate(),
      );
      break;
    case 'P60D':
      startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 2,
        endDate.getDate(),
      );
      break;
    case 'P90D':
      startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 3,
        endDate.getDate(),
      );
      break;
    case 'P6M':
      startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 6,
        endDate.getDate(),
      );
      break;
    case 'P3M':
      startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 3,
        endDate.getDate(),
      );
      break;
    default:
      throw new Error(`Invalid period: ${parts[1]}`);
  }
  return { startDate, endDate };
}

export function midDate(startDate: Date, endDate: Date) {
  return new Date(
    startDate.getTime() + (endDate.getTime() - startDate.getTime()) / 2,
  );
}

export function getLastCompleteBillingDate(): string {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString().split('T')[0]; // format YYYY-MM-DD
}
