import React from 'react';
import { Content, Header, Page } from '@backstage/core-components';
import {
   HomePageRecentlyVisited,
   HomePageStarredEntities, HomePageToolkit, HomePageTopVisited, WelcomeTitle
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SonarIcon from '../../assets/icons/SonarIcon';
import GithubIcon from '../../assets/icons/GithubIcon';
import AWSIcon from '../../assets/icons/AWSIcon';
import JiraIcon from '../../assets/icons/JiraIcon';
import { AnnouncementsCard, NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';

export const HomePage = () => {

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="T-Systems Developer Portal" />
        <Content>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <h1 style={{ fontSize: '24px' }}><WelcomeTitle language={['English']} /></h1>
            {/* useStyles has a lower precedence over mui styles hence why we need to use css */}
            <HomePageSearchBar
              placeholder="Search"
            />
            <Grid container>
              <Grid item md={12}>
                <NewAnnouncementBanner />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title='Useful Links' tools={[
                  {
                    url: 'https://setools.t-systems.es/trm_sonar/dashboard?id=ENDU-BACKSTAGE',
                    label: 'SonarQube',
                    icon: <SonarIcon />,
                  },
                  {
                    url: 'https://eu-west-1.console.aws.amazon.com/',
                    label: 'AWS Management Console',
                    icon: <AWSIcon />,
                  },
                  {
                    url: 'https://setools.t-systems.es/jira',
                    label: 'Jira',
                    icon: <JiraIcon />,
                  },
                  {
                    url: 'https://github.com',
                    label: 'Github',
                    icon: <GithubIcon />,
                  },
                ]} />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <HomePageRandomJoke />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited />
              </Grid>
              <Grid item md={6}>
                <AnnouncementsCard max={3} />
              </Grid>
            </Grid>
          </Box>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};