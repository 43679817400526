import { CostExplorerClient } from '@aws-sdk/client-cost-explorer';
import { ResourceGroupsTaggingAPIClient } from '@aws-sdk/client-resource-groups-tagging-api';
import { CostManagementClient } from '@azure/arm-costmanagement';
import { Tag } from '@internal/plugin-cost-insights-tsystems';

export interface AWSCostClient{
    account: string;
    provider: CloudProviders;
    name: string;
    tags: Tag[];
    ceClient: CostExplorerClient;
    tagClient: ResourceGroupsTaggingAPIClient;
}

export interface AzureCostClient{
    account: string;
    provider: CloudProviders;
    name: string;
    tags: Tag[];
    ceClient: CostManagementClient;
}

export interface CloudCatalogCosts{
    key: string;
    name: string;
    componentsTags: string[];
}

export enum CloudProviders {
    AWS = 'aws',
    AZURE = 'azure',
    GCP = 'gcp'
}