import { AzureCostClient } from "../cost.model";
import { ClientSecretCredential } from '@azure/identity';
import { CostManagementClient } from '@azure/arm-costmanagement';

export function initializeClientAzure(account: any): AzureCostClient{
  // const tenentId = account.get('tenentId');
  // const clientId = account.get('clientId');
  // const clientSecret = account.get('clientSecret');
  // const subscriptionId = account.get('subscriptionId');

  const tenantId = '417b7046-7a35-4da1-a46e-2ca5e8cf5e49'
  const clientId = '024e6976-a6ef-4fd2-bcd5-ec33d73e662d'
  const clientSecret = '.Z-8Q~FCRiuNAc7IPeMChuBGA1mb.-1kyH6FKcKo'
  const subscriptionId = '2c1ff255-1397-42e6-896e-18bcde584b6f'

  // 801b75e8-794d-4e1e-827b-e45b5291be91
  // .Z-8Q~FCRiuNAc7IPeMChuBGA1mb.-1kyH6FKcKo

  // const credential: AccessToken = await getAccessToken();
  const credential = new ClientSecretCredential(tenantId, clientId, clientSecret);
  // const credential = new InteractiveBrowserCredential({tenantId, clientId});

  const client = new CostManagementClient(credential);

  const fullClient: AzureCostClient = {
      account: account.get('account'),
      provider: account.get('provider'),
      tags: [],
      name: account.get('name'),
      ceClient: client,
  }
  console.log('FullClient', fullClient);
  return fullClient;
}