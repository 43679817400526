import {
  CostExplorerClient,
  GetCostAndUsageCommand,
  GetCostAndUsageCommandInput,
} from '@aws-sdk/client-cost-explorer';
import {
  Cost,
  ProductInsightsOptions,
  Entity,
  Tag,
} from '@internal/plugin-cost-insights-tsystems';
import { midDate } from '../insightsUtils';

export function _getGroupDailyCost(
  client: CostExplorerClient,
  intervalDates: any,
  group: string,
): Promise<Cost> {
  return new Promise(async (resolve, reject) => {
    const command = new GetCostAndUsageCommand({
      TimePeriod: {
        Start: intervalDates.startDate.toISOString().slice(0, 10),
        End: intervalDates.endDate.toISOString().slice(0, 10),
      },
      Metrics: ['UNBLENDED_COST'],
      Filter: {
        Dimensions: { Key: 'LINKED_ACCOUNT', Values: [group] },
      },
      Granularity: 'DAILY',
    });
    try {
      const data = await client.send(command);

      const aggregation = data.ResultsByTime
        ? data.ResultsByTime.map(item => ({
            date: item.TimePeriod?.Start ?? '',
            amount: parseFloat(item.Total?.UnblendedCost?.Amount ?? '0'),
          }))
        : [];
      const intercept =
        aggregation.reduce((sum, item) => sum + item.amount, 0) /
        aggregation.length;
      // Calculate the ratio (change from first to last value)
      const firstValue = aggregation[0]?.amount || 0;
      const lastValue = aggregation[aggregation.length - 1]?.amount || 0;
      const ratio =
        firstValue !== 0 ? (lastValue - firstValue) / firstValue : 0;

      // Calculate the amount (difference between first and last value)
      const amount = lastValue - firstValue;

      const dailyCost: Cost = {
        id: 'daily-cost',
        trendline: { slope: 0, intercept }, // slope inclinació, intercept is the horizontal value (the average)
        change: {
          ratio, // Change from first value to last value of the aggregation
          amount,
        },
        aggregation,
      };
      resolve(dailyCost);
    } catch (error) {
      reject(error);
    }
  });
}

export function _getProjectDailyCostByTags(
  client: CostExplorerClient,
  intervalDates: any,
  tags: Tag[],
  project: string,
): Promise<Cost> {
  return new Promise(async (resolve, reject) => {
    const filteredTags = tags.filter(tag => tag.values.length > 0);
    const tagFilter = filteredTags.length > 1 ? { Or: filteredTags.map(tag => ({ Tags: { Key: tag.key, Values: tag.values } })) } : { Tags: { Key: filteredTags[0]?.key, Values: filteredTags[0]?.values } };
    const command = new GetCostAndUsageCommand({
      TimePeriod: {
        Start: intervalDates.startDate.toISOString().slice(0, 10),
        End: intervalDates.endDate.toISOString().slice(0, 10),
      },
      Metrics: ['UNBLENDED_COST'],
      Filter: {
        And: [
          { Dimensions: { Key: 'LINKED_ACCOUNT', Values: [project] } },
          ...filteredTags.length > 0 ? [tagFilter] : [],
        ],
      },
      Granularity: 'DAILY',
    });
    try {
      const data = await client.send(command);
      const aggregation = data.ResultsByTime
        ? data.ResultsByTime.map(item => ({
            date: item.TimePeriod?.Start ?? '',
            amount: parseFloat(item.Total?.UnblendedCost?.Amount ?? '0'),
          }))
        : [];
      const intercept =
        aggregation.reduce((sum, item) => sum + item.amount, 0) /
        aggregation.length;
      // Calculate the ratio (change from first to last value)
      const firstValue = aggregation[0]?.amount || 0;
      const lastValue = aggregation[aggregation.length - 1]?.amount || 0;
      const ratio =
        firstValue !== 0 ? (lastValue - firstValue) / firstValue : 0;

      // Calculate the amount (difference between first and last value)
      const amount = lastValue - firstValue;

      const dailyCost: Cost = {
        id: 'daily-cost',
        trendline: { slope: 0, intercept }, // slope inclinació, intercept is the horizontal value (the average)
        change: {
          ratio, // Change from first value to last value of the aggregation
          amount,
        },
        aggregation,
      };
      resolve(dailyCost);
    } catch (error) {
      reject(error);
    }
  });
}

export enum ProductTypes {
  ACCOUNTSERVICE = 'AccountService',
  PROJECT1 = 'ProjectService1',
  PROJECT2 = 'ProjectService2',
}

export function _getProductInsights(
  client: CostExplorerClient,
  intervalDates: any,
  options: ProductInsightsOptions,
  tags: Tag[],
): Promise<Entity> {
  return new Promise(async (resolve, reject) => {
    let filters = {};
    const filteredTags = tags.filter(tag => tag.values.length > 0);
    const tagFilter = filteredTags.length > 1 ? { Or: filteredTags.map(tag => ({ Tags: { Key: tag.key, Values: tag.values } })) } : { Tags: { Key: filteredTags[0]?.key, Values: filteredTags[0]?.values } };

    switch (options.product) {
      case ProductTypes.ACCOUNTSERVICE:
        if (tags.length > 0) {
          filters = {
            And: [
              { Dimensions: { Key: 'LINKED_ACCOUNT', Values: [options.group] } },
              ...filteredTags.length > 0 ? [tagFilter] : [],
            ],
          };
        } else {
          filters = {
            Dimensions: { Key: 'LINKED_ACCOUNT', Values: [options.group] },
          };
        }
        break;
      default:
        filters = {};
    }
    const mid = midDate(intervalDates.startDate, intervalDates.endDate);
    const commandInput: GetCostAndUsageCommandInput = {
      TimePeriod: {
        Start: intervalDates.startDate.toISOString().slice(0, 10),
        End: mid.toISOString().slice(0, 10),
        // End: intervalDates.endDate.toISOString().slice(0, 10),
      },
      Metrics: ['UNBLENDED_COST'],
      Granularity: 'DAILY',
      Filter: filters,
      GroupBy: [
        {
          Type: 'DIMENSION',
          Key: 'SERVICE',
        },
      ],
    };
    try {
      const data1 = await client.send(new GetCostAndUsageCommand(commandInput));
      commandInput.TimePeriod = {
        Start: mid.toISOString().slice(0, 10),
        // Start: intervalDates.startDate.toISOString().slice(0, 10),
        End: intervalDates.endDate.toISOString().slice(0, 10),
      };
      const data2 = await client.send(new GetCostAndUsageCommand(commandInput));
      const dailyCost: Entity = mapCostToEntity(
        data1.ResultsByTime ?? [],
        data2.ResultsByTime ?? [],
      );
      resolve(dailyCost);
    } catch (error) {
      reject(error);
    }
  });
}

function mapCostToEntity(results1: any[], results2: any[]): Entity {
  const costByService: { [key: string]: number[] } = {};

  let globalCost1 = 0;
  for (const result of results1) {
    for (const group of result.Groups) {
      const service = group.Keys[0];
      const cost = parseFloat(group.Metrics.UnblendedCost.Amount);
      globalCost1 += cost;

      if (costByService[service]) {
        costByService[service][0] += cost;
      } else {
        costByService[service] = [cost, 0];
      }
    }
  }

  let globalCost2 = 0;
  for (const result of results2) {
    for (const group of result.Groups) {
      const service = group.Keys[0];
      const cost = parseFloat(group.Metrics.UnblendedCost.Amount);
      globalCost2 += cost;

      if (costByService[service]) {
        costByService[service][1] += cost;
      } else {
        costByService[service] = [0, cost];
      }
    }
  }

  const serviceEntities: Entity[] = Object.entries(costByService).map(
    ([id, costs]) => {
      const [cost1, cost2] = costs;
      const ratio = cost1 !== 0 ? (cost2 - cost1) / cost1 : 0;
      const amount = cost2 - cost1;

      return {
        id,
        aggregation: [cost1, cost2],
        entities: {},
        change: {
          ratio,
          amount,
        },
      };
    },
  );

  const dailyCost: Entity = {
    id: 'product',
    aggregation: [globalCost1, globalCost2],
    change: {
      ratio: globalCost1 !== 0 ? (globalCost2 - globalCost1) / globalCost1 : 0,
      amount: globalCost2 - globalCost1,
    },
    entities: {
      service: serviceEntities,
    },
  };

  return dailyCost;
}
